<script setup lang="ts">
import type { Intention } from '@/type'
import { admin_editor_intention_path } from '@/routes'
import { submitForm } from '@/utils/form'

const props = defineProps<{
  intention: Intention
}>()

function save(intention: Intention) {
  // FIXME: use inertia form
  const formData = new FormData()
  Object.entries(intention).forEach(([key, value]) => {
    formData.append(key, value as string) // Ensure values are strings or convert them appropriately
  })

  submitForm('put', admin_editor_intention_path(props.intention.id), formData)
}
</script>

<template lang="pug">
intention-editor(:intention="props.intention", @save="save")
</template>
