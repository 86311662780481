<script lang="tsx" setup>
import type { Intention } from '@/type'
import {
  admin_editor_intention_path,
  edit_admin_editor_intention_path,
  new_admin_editor_intention_path,
} from '@/routes'
import { router } from '@inertiajs/vue3'

import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  intentions: Intention[]
}>()

const { t } = useI18n()

const columns = computed(() => [
  {
    colKey: 'identifier',
    title: t('intentions.identifier'),
  },
  {
    colKey: 'topic_tolerance',
    title: t('intentions.topic_tolerance'),
  },
  {
    colKey: 'emotion_tolerance',
    title: t('intentions.emotion_tolerance'),
  },
  {
    colKey: 'operations',
    title: t('intentions.operations'),
    cell: (_h: unknown, { row }: { row: Intention }) => (
      <t-space size="small">
        <t-button
          theme="primary"
          variant="text"
          onClick={() => showIntention(row)}
        >
          {t('intentions.test')}
        </t-button>
        <t-button
          theme="warning"
          variant="text"
          onClick={() => editIntention(row)}
        >
          {t('intentions.edit')}
        </t-button>
        <t-button
          theme="danger"
          variant="text"
          onClick={() => deleteIntention(row)}
        >
          {t('intentions.delete')}
        </t-button>
      </t-space>
    ),
  },
])

function createIntention() {
  router.visit(new_admin_editor_intention_path())
}

function showIntention(intention: Intention) {
  router.visit(admin_editor_intention_path(intention.id))
}

function editIntention(intention: Intention) {
  router.visit(edit_admin_editor_intention_path(intention.id))
}

function deleteIntention(intention: Intention) {
  router.delete(admin_editor_intention_path(intention.id))
}
</script>

<template lang="pug">
#intentions-index
  t-button(theme="primary", @click="createIntention")
    | {{ t("intentions.create") }}
  t-table.table(row-key="id", :data="props.intentions", :columns="columns")
</template>

<style lang="stylus" scoped>
.table
  margin-top 1rem
</style>
