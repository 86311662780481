<script setup lang="ts">
import En from '@/assets/blockly/messages/en'
import ZhCn from '@/assets/blockly/messages/zh_CN'
import Toolbox from '@/assets/blockly/toolbox'
import DarkTheme from '@blockly/theme-dark'

import * as Blockly from 'blockly/core'
import { onMounted, ref, watch } from 'vue'

import { useI18n } from 'vue-i18n'

/* eslint-disable vue/prop-name-casing */
const props = defineProps<{
  current_locale: string
}>()
/* eslint-enable vue/prop-name-casing */

const { t } = useI18n()

const blockly = ref<Element | null>(null)
let workspace: Blockly.Workspace | null = null

function setBlocklyLocale() {
  switch (props.current_locale) {
    case 'en':
      Blockly.setLocale(En)
      break
    case 'zh_CN':
      Blockly.setLocale(ZhCn)
      break
    default:
      Blockly.setLocale(En)
  }
}

function configureWorkspace() {
  if (!blockly.value)
    return

  setBlocklyLocale()
  workspace = Blockly.inject(blockly.value, {
    theme: DarkTheme,
    toolbox: Toolbox,
  })
}

function save() {
  if (!workspace)
    return

  throw new Error('Not implemented')
}

watch(() => props.current_locale, configureWorkspace)

onMounted(() => {
  configureWorkspace()
})
</script>

<template lang="pug">
#chats-new
  #blockly(ref="blockly")
  t-button(@click="save") {{ t("blockly.save") }}
</template>

<style scoped lang="stylus">
#blockly
  width 100%
  height 500px
</style>
