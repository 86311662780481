<script setup lang="ts">
import { submitForm } from '@/utils/form'
import { router } from '@inertiajs/vue3'
import { onMounted, ref, watch } from 'vue'

import { useI18n } from 'vue-i18n'

/* eslint-disable vue/prop-name-casing */
const props = defineProps<{
  current_user?: {
    id: number
  }
  current_locale: string
}>()
// import {
//   admin_editor_intentions_path,
//   admin_operations_games_path,
//   admin_operations_users_path,
//   admin_root_path,
//   auth_index_path,
//   locale_index_path,
//   sessions_path,
// } from "@/routes";

const pathname = ref(window.location.pathname)
router.on('navigate', event => (pathname.value = event.detail.page.url))

/* eslint-enable vue/prop-name-casing */

const switchRoute = (value: string) => router.visit(value)
function switchLocale(value: string) {
  submitForm('patch', locale_index_path(), {
    locale: value,
  })
}

// i18n
const { t, locale } = useI18n()

function logout() {
  submitForm('delete', sessions_path(), {})
}

onMounted(() => {
  // check login state
  if (!props.current_user) {
    router.visit(auth_index_path(), {
      data: {
        auth: {
          redirect: window.location.href,
        },
      },
    })
    return
  }

  // initial app language
  locale.value = props.current_locale
})

watch(
  () => props.current_locale,
  (value) => {
    locale.value = value
  },
)
</script>

<template lang="pug">
t-layout
  t-header.header
    t-head-menu(v-model="pathname", expand-type="popup", @change="switchRoute")
      template(#logo)
        img.logo-banner(height="36", src="@/assets/images/logo_banner.png", alt="logo", @click="switchRoute(admin_root_path())")
      t-submenu(:title="t('header.operations')")
        t-menu-item(:value="admin_operations_games_path()", :router="{}")
          | {{ t('header.games') }}
        t-menu-item(:value="admin_operations_users_path()", :router="{}")
          | {{ t('header.users') }}
      t-submenu(:title="t('header.editor')")
        t-menu-item(value="/events", :router="{}")
          | {{ t('header.characters') }}
        t-menu-item(value="/events", :router="{}")
          | {{ t('header.places') }}
        t-menu-item(value="/events", :router="{}")
          | {{ t('header.points_of_interest') }}
        t-menu-item(value="/events", :router="{}")
          | {{ t('header.archives') }}
        t-menu-item(value="/events", :router="{}")
          | {{ t('header.chats') }}
        t-menu-item(:value="admin_editor_intentions_path()", :router="{}")
          | {{ t('header.intentions') }}
      template(#operations)
        t-space
          t-select(:value="props.current_locale", @change="switchLocale")
            t-option(key="en", label="English", value="en")
            t-option(key="zh_CN", label="简体中文", value="zh_CN")
          span
            | {{ current_user?.email }}
          t-button(theme="danger", @click="logout")
            | {{ t('header.logout') }}
  t-layout.content
    slot
  t-footer
    t-divider.footer
      | YetAnother.AI © 2023-{{ new Date().getFullYear() }}
</template>

<style lang="stylus" scoped>
body
  margin 0

.header
  border-bottom #202020 solid 1px

.logo-banner
  margin-left 32px
  cursor pointer
.content
  margin 20px
  min-height calc(100vh - 200px)

.footer
  font-family monospace

.fade-enter-active, .fade-leave-active
  transition-property opacity
  transition-duration .2s

.fade-enter-active
  transition-delay .2s

.fade-enter, .fade-leave-active
  opacity: 0
</style>
