<script setup lang="ts">
import type { Intention } from '@/type'

import { admin_editor_intentions_path } from '@/routes'
import { submitForm } from '@/utils/form'
import { reactive } from 'vue'

const intention = reactive({
  identifier: '',
  topic_tolerance: 0.2,
  emotion_tolerance: 0.2,
  samples: [{ id: 1, content: '' }],
})

function save(intention: Intention) {
  // FIXME: use inertia form
  const formData = new FormData()
  Object.entries(intention).forEach(([key, value]) => {
    formData.append(key, value as string)
  })

  submitForm('post', admin_editor_intentions_path(), formData)
}
</script>

<template lang="pug">
intention-editor(:intention="intention", @save="save")
</template>
