<script setup lang="ts">
import layout from '@/layouts/minimal.vue'
import { auth_path } from '@/routes'
import { onMounted, ref } from 'vue'

defineOptions({ layout })

const props = defineProps<{
  provider: 'developer' | 'yauth'
}>()

const hiddenForm = ref<HTMLFormElement | null>(null)

function loginByProvider() {
  // can't use form.submitForm here because this route returns HTML directly
  hiddenForm.value?.setAttribute('action', auth_path(props.provider))
  hiddenForm.value?.submit()
}

onMounted(() => loginByProvider())
</script>

<template lang="pug">
.login
  form(v-show="false", ref="hiddenForm", method="post")
    input(type="hidden", name="authenticity_token", :value="authenticityToken()")
    button(type="submit")
</template>

<style lang="stylus" scoped>
.form
  width 350px
</style>
